<template>
  <div>
    <iframe
      id="graphic_pbi"
      width="100%"
      height="900px"
      :src="linkIframe"
      frameborder="0"
      allowFullScreen="true"
    />
  </div>
</template>

<script>
import axios from 'axios'
import useAppConfig from '@core/app-config/useAppConfig'
import router from '@/router'

export default {
  components: {
  },
  data() {
    return {
      linkIframe: null,
      semuatanggal: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const params = new URLSearchParams()
      params.append('mon', router.currentRoute.params.mon)
      params.append('week', router.currentRoute.params.week)
      const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/filter-tanggal-pbi/view`, params)
      const DataAPI = result.data.data
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        this.semuatanggal += `${DataAPI[i].tanggal},`
        console.log(DataAPI[i].tanggal)
      }

      const res = await axios.get('https://api.dbc.co.id/v2/dias/genLinkEmbed', {
        params: {
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZG1hcCI6Ik9QUiAtIEVuZXJneSIsImlhdCI6MTY4NjY1MTEwOX0.ig_KExxPZo_3hvr91T08YfBnleTTjh46cmY4XXFAn5s',
          nik: '2000820',
          filter_embed: `DM_Energy_Report/date in (${this.semuatanggal.slice(0, -1)})`,
        },
      })
      this.linkIframe = res.data
    },
  },
}
</script>
